.slideshow-container
{   
    width: 100%;
    height: 40vw;
    min-height: 320px;
    max-height: 720px;

    z-index: 1;
}

.slideshow-container-overlay
{
    position: absolute;

    width: 100%;
    height: 40vw;
    min-height: 320px;
    max-height: 720px;

    z-index: 3;
}

.slideshow-background-concept-concept1
{
    background-color: #000000;
    background-image: url('/public/image/concept/concept1.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 80%;
}

.slideshow-background-concept-concept2
{
    background-color: #000000;
    background-image: url('/public/image/concept/concept2.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 70%;
}

.slideshow-background-concept-concept3
{
    background-color: #000000;
    background-image: url('/public/image/concept/concept3.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 35%;
}

.slideshow-background-concept-concept4
{
    background-color: #000000;
    background-image: url('/public/image/concept/concept4.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 60%;
}

.slideshow-background-concept-concept5
{
    background-color: #000000;
    background-image: url('/public/image/concept/concept5.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 70%;
}

.slideshow-background-day-apartments1
{
    background-color: #000000;
    background-image: url('/public/image/day/apartments1.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 70%;
}

.slideshow-background-day-apartments2
{
    background-color: #000000;
    background-image: url('/public/image/day/apartments2.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 70%;
}

.slideshow-background-day-apartments3
{
    background-color: #000000;
    background-image: url('/public/image/day/apartments3.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 70%;
}

.slideshow-background-day-houses1
{
    background-color: #000000;
    background-image: url('/public/image/day/houses1.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 70%;
}

.slideshow-background-day-houses2
{
    background-color: #000000;
    background-image: url('/public/image/day/houses2.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 70%;
}

.slideshow-background-interior-interior1
{
    background-color: #000000;
    background-image: url('/public/image/interior/interior1.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 70%;
}

.slideshow-background-interior-interior2
{
    background-color: #000000;
    background-image: url('/public/image/interior/interior2.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 70%;
}

.slideshow-background-night-apartments1
{
    background-color: #000000;
    background-image: url('/public/image/night/apartments1.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 70%;
}

.slideshow-background-night-apartments2
{
    background-color: #000000;
    background-image: url('/public/image/night/apartments2.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 85%;
}

.slideshow-background-night-apartments3
{
    background-color: #000000;
    background-image: url('/public/image/night/apartments3.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 65%;
}

.slideshow-background-night-apartments4
{
    background-color: #000000;
    background-image: url('/public/image/night/apartments4.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 65%;
}

.slideshow-background-night-apartments5
{
    background-color: #000000;
    background-image: url('/public/image/night/apartments5.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 65%;
}

.slideshow-background-sunset-apartments1
{
    background-color: #000000;
    background-image: url('/public/image/sunset/apartments1.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 70%;
}

.slideshow-background-sunset-apartments2
{
    background-color: #000000;
    background-image: url('/public/image/sunset/apartments2.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 70%;
}

.slideshow-background-sunset-apartments3
{
    background-color: #000000;
    background-image: url('/public/image/sunset/apartments3.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 70%;
}

.slideshow-background-sunset-houses1
{
    background-color: #000000;
    background-image: url('/public/image/sunset/houses1.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 70%;
}

.slideshow-background-sunset-parking1
{
    background-color: #000000;
    background-image: url('/public/image/sunset/parking1.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 72%;
}

/* XS, SM */
@media (max-width: 767.98px)
{
    .slideshow-background-concept-concept1
    {
        background-image: url('/public/image/concept/concept1_mobile.jpg');
    }

    .slideshow-background-concept-concept2
    {
        background-image: url('/public/image/concept/concept2_mobile.jpg');
    }

    .slideshow-background-concept-concept3
    {
        background-image: url('/public/image/concept/concept3_mobile.jpg');
    }

    .slideshow-background-concept-concept4
    {
        background-image: url('/public/image/concept/concept4_mobile.jpg');
    }

    .slideshow-background-concept-concept5
    {
        background-image: url('/public/image/concept/concept5_mobile.jpg');
    }

    .slideshow-background-day-apartments1
    {
        background-image: url('/public/image/day/apartments1_mobile.jpg');
    }

    .slideshow-background-day-apartments2
    {
        background-image: url('/public/image/day/apartments2_mobile.jpg');
    }

    .slideshow-background-day-apartments3
    {
        background-image: url('/public/image/day/apartments3_mobile.jpg');
    }

    .slideshow-background-day-houses1
    {
        background-image: url('/public/image/day/houses1_mobile.jpg');
    }

    .slideshow-background-day-houses2
    {
        background-image: url('/public/image/day/houses2_mobile.jpg');
    }

    .slideshow-background-interior-interior1
    {
        background-image: url('/public/image/interior/interior1_mobile.jpg');
    }

    .slideshow-background-interior-interior2
    {
        background-image: url('/public/image/interior/interior2_mobile.jpg');
    }

    .slideshow-background-night-apartments1
    {
        background-image: url('/public/image/night/apartments1_mobile.jpg');
    }

    .slideshow-background-night-apartments2
    {
        background-image: url('/public/image/night/apartments2_mobile.jpg');
    }

    .slideshow-background-night-apartments3
    {
        background-image: url('/public/image/night/apartments3_mobile.jpg');
    }

    .slideshow-background-night-apartments4
    {
        background-image: url('/public/image/night/apartments4_mobile.jpg');
    }

    .slideshow-background-night-apartments5
    {
        background-image: url('/public/image/night/apartments5_mobile.jpg');
    }

    .slideshow-background-sunset-apartments1
    {
        background-image: url('/public/image/sunset/apartments1_mobile.jpg');
    }

    .slideshow-background-sunset-houses1
    {
        background-image: url('/public/image/sunset/houses1_mobile.jpg');
    }

    .slideshow-background-sunset-parking1
    {
        background-image: url('/public/image/sunset/parking1_mobile.jpg');
    }
}

@keyframes slideshow-fade-1
{
    from { opacity: 0.0; }
    to { opacity: 1.0; }
}

.slideshow-fade-1
{
    opacity: 1.0; 

    animation-name: slideshow-fade-1;
    animation-duration: 0.25s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

@keyframes slideshow-fade-1
{
    from { opacity: 0.0; }
    to { opacity: 1.0; }
}

.slideshow-fade-2
{
    opacity: 1.0;

    animation-name: slideshow-fade-2;
    animation-duration: 0.25s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

@keyframes slideshow-fade-2
{
    from { opacity: 0.0; }
    to { opacity: 1.0; }
}

.slideshow-fade-3
{
    opacity: 1.0;

    animation-name: slideshow-fade-3;
    animation-duration: 0.25s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

@keyframes slideshow-fade-3
{
    from { opacity: 0.0; }
    to { opacity: 1.0; }
}

.slideshow-fade-4
{
    opacity: 1.0;

    animation-name: slideshow-fade-4;
    animation-duration: 0.25s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

@keyframes slideshow-fade-4
{
    from { opacity: 0.0; }
    to { opacity: 1.0; }
}

.slideshow-fade-5
{
    opacity: 1.0;

    animation-name: slideshow-fade-5;
    animation-duration: 0.25s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

@keyframes slideshow-fade-5
{
    from { opacity: 0.0; }
    to { opacity: 1.0; }
}

.slideshow-fade-6
{
    opacity: 1.0;

    animation-name: slideshow-fade-6;
    animation-duration: 0.25s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

@keyframes slideshow-fade-6
{
    from { opacity: 0.0; }
    to { opacity: 1.0; }
}

.slideshow-fade-7
{
    opacity: 1.0;

    animation-name: slideshow-fade-7;
    animation-duration: 0.25s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

@keyframes slideshow-fade-7
{
    from { opacity: 0.0; }
    to { opacity: 1.0; }
}

.slideshow-fade-8
{
    opacity: 1.0;

    animation-name: slideshow-fade-8;
    animation-duration: 0.25s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

@keyframes slideshow-fade-8
{
    from { opacity: 0.0; }
    to { opacity: 1.0; }
}

.slideshow-fade-9
{
    opacity: 1.0;

    animation-name: slideshow-fade-9;
    animation-duration: 0.25s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

@keyframes slideshow-fade-9
{
    from { opacity: 0.0; }
    to { opacity: 1.0; }
}

.slideshow-fade-10
{
    opacity: 1.0;

    animation-name: slideshow-fade-6;
    animation-duration: 0.25s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

@keyframes slideshow-fade-10
{
    from { opacity: 0.0; }
    to { opacity: 1.0; }
}

.slideshow-section
{
    position: relative;

    overflow: hidden;

    width: 100%;
    height: 40vw;
    min-height: 320px;
    max-height: 720px;

    background-color: #ffffff;
    background-position: center top;
    background-size: cover;

    padding-bottom: 48px;
}

.slideshow-thumbnail-container
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    column-gap: 15px;
    flex-wrap: nowrap;
    -webkit-box-align: flex-end;
    -ms-flex-align: flex-end;
    align-items: flex-end;
}

.slideshow-thumbnail
{
    height: 64px;
    margin-top: 32px;
    margin-bottom: 16px;

    text-overflow: ellipsis;
    overflow: hidden;

    transition: color background-color 0.25s linear;
    -moz-transition: color background-color 0.25s linear;
    -webkit-transition: color background-color 0.25s linear;

    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.slideshow-thumbnail:hover
{
    cursor: pointer;
}

/* XS, SM */
@media (max-width: 767.98px)
{
    .slideshow-thumbnail
    {
        height: 16px;
    }
}

/* MD */
@media (min-width: 768px) and (max-width: 991.98px)
{
    .slideshow-thumbnail
    {
        height: 32px;
    }
}

.slideshow-thumbnail-flex
{
    width: 100%;
}

.slideshow-thumbnail-gradient
{
    position: absolute;
    bottom: 0px;
    width: 100%;

    background: rgb(0,0,0);
    background: -moz-linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
}

@keyframes slideshow-thumbnail-timer-animation-keyframes
{
    from
    {
        width: 0px;
    }

    to
    {
        width: 100%;
    }
}

.slideshow-thumbnail-timer-animation
{
    height: 3px;

    background-color: #298ce8;

    animation-name: slideshow-thumbnail-timer-animation-keyframes;
    animation-duration: 12s;
    animation-timing-function: linear;
}

.slideshow-thumbnail-timer
{
    background-color: rgba(255, 255, 255, 0.4);
    
    height: 3px;
}

/* XS, SM, MD */
@media (max-width: 991.98px)
{
    .slideshow-thumbnail-timer-animation
    {
        height: 5px;
    }

    .slideshow-thumbnail-timer
    {
        height: 5px;
    }
}

.slideshow-thumbnail-title
{
    margin-top: 14px;

    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    text-align: left;

    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
}

/* XS, SM */
@media (max-width: 767.98px)
{
    .slideshow-thumbnail-title
    {
        display: none !important;
    }
}