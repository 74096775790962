.footer-limburg
{
    display: inline-block;

    margin-left: 2px;
    margin-right: 3px;
    margin-bottom: -3px;

    width: 12px;
    height: 30px;

    background-image: url("/public/image/icon_limburg.svg");
    background-repeat: no-repeat;
    background-size: 12px 30px;
}

.footer-line
{
    height: 1px;

    margin-top: 12px;
    margin-bottom: 12px;

    background-color: #939393;
    color: #939393;
    opacity: 0.5;
}

.footer-link
{
    display: inline-block;

    margin-right: 24px;
    
    color: #FFFFFF;
}

.footer-link:hover
{
    color: #FFFFFF;

    cursor: pointer;

    text-decoration: underline;
}

.footer-link-seperator
{
    display: none;

    margin-right: 24px;
    
    color: #FFFFFF;
}

/* MD and up */
@media (min-width: 768px)
{
    .footer-link-seperator
    {
        display: inline-block;
    }
}

.footer-section
{
    padding-top: 48px;
    padding-bottom: 48px;

    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;

    color: #ffffff;

    background-color: #252525;
    background-position: center top;
    background-size: cover;
}

.footer-text
{
    font-size: 16px;
    line-height: 22px;

    color: #939393;
}

.footer-text-location
{
    margin-top: 16px;

    font-size: 16px;
    line-height: 22px;

    color: #939393;
}

.footer-title-section
{
    margin-bottom: 24px;
}

.footer-title
{
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    height: 100%;

    color: #939393;
    
    font-family: Heebo;
    font-size: 20px;
    font-weight: 500;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
}

.footer-title:hover
{
    cursor: pointer;

    color: #939393;
}

.footer-title-trademark
{
    padding-top: 2px;
    padding-left: 1px;
    
    font-family: Heebo;
    font-size: 10px;
    font-weight: 500;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
}