.banner-category
{
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    text-align: left;

    color: #ffffff;
}

.banner-image
{
    position: absolute;
    z-index: 0;
    object-fit: cover;

    width: 100%;
    height: 100%;
    top: 0px;

    background-image: url('/public/image/banner.jpg');
    background-size: cover;
    background-position: center;
}

/* XS, SM */
@media (max-width: 767.98px)
{
    .banner-image
    {
        background-image: url('/public/image/banner_mobile.jpg');
        background-position: 25% 50%;
    }
}

.banner-overlay
{
    position: absolute;
    width: 100%;
}

.banner-section
{
    position: relative;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    height: calc(100vh - 40px);
    max-height: 560px;
    min-height: 460px;
    overflow: hidden;
    
    background-color: #333;
    background-position: center top;
    background-size: cover;
}

.banner-title-mobile
{
    margin: 0;
    margin-top: 12px;
    margin-bottom: 0px;

    font-size: 42px;
    line-height: 46px;

    font-weight: 600;
    -webkit-font-smoothing: antialiased;

    color: #ffffff;
}

.banner-title-tablet
{
    margin: 0;
    margin-top: 12px;
    margin-bottom: 0px;

    font-size: 42px;
    line-height: 46px;

    font-weight: 600;
    -webkit-font-smoothing: antialiased;

    color: #ffffff;
}

.banner-title-desktop
{
    margin: 0;
    margin-top: 12px;
    margin-bottom: 0px;

    font-size: 54px;
    line-height: 60px;
    
    font-weight: 600;
    -webkit-font-smoothing: antialiased;

    color: #ffffff;
}

