.form-accept
{
    display: none;
}

.form-checkbox-container
{
    margin-top: 15px;

    color: #212529;

    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    text-align: left;
}

.form-checkbox input
{
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.form-checkbox
{
    position: relative;
    display: block;
    margin-top: 20px;
}

.form-checkbox span
{
    display: block;
    margin-left: 30px;

    text-align: left;
}

.form-checkbox span::before
{
    content: "";
    display: block;
    position: absolute;

    width: 20px;
    height: 20px;
    left: 0;
    top: 0;

    background: #ffffff;

    border: 1px solid #c3c3c3;
    border-radius: 6px;
}

.form-checkbox:hover
{
    cursor: pointer;
}

.form-checkbox-active input
{
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.form-checkbox-active
{
    position: relative;
    display: block;
    margin-top: 20px;
}

.form-checkbox-active span
{
    display: block;
    margin-left: 30px;

    text-align: left;
}
  
.form-checkbox-active span::before
{
    content: "";
    display: block;
    position: absolute;

    width: 20px;
    height: 20px;
    left: 0;
    top: 0;

    background: #298ce8;

    border: 2px solid #298ce8;
    border-radius: 6px;
}

.form-checkbox-active:hover
{
    cursor: pointer;
}

.form-error
{
    color: #d73030;
    
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    text-align: left;
}

.form-loading
{
    margin-top: 12px;

    width: 48px;
    height: 48px;

    background-image: url("/public/image/icon_loading.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 48px 48px;

    animation-name: spin;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

.form-multiline
{
    margin-top: 15px;
    margin-bottom: 5px;
    padding-top: 15px;
    padding-bottom: 0px;
    
    width: 100%;
    min-height: 86px;
    
    color: #212529;
    
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;

    border: none;
    border-bottom: 1px solid #c3c3c3;
}

.form-multiline:focus
{
    outline-color: #298ce8;
}

.form-section
{
    margin-top: 64px;
    margin-bottom: 48px;

    background-color: #ffffff;
}

.form-send
{
    margin-top: 30px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 32px;
    padding-right: 32px;

    background-color: #298ce8;
    color: #ffffff;

    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    text-decoration: none;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.125);

    border-color: #298ce8;
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;

    transition: all .25s ease-out;
}

.form-send:hover
{
    background-color: #3d9bf4;

    text-decoration: none;

    border-color: #298ce8;
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;

    cursor: pointer;

    transition: all .25s ease-out;
}

.form-singleline
{
    margin-top: 15px;
    padding-top: 15px;
    padding-bottom: 5px;

    width: 100%;
    height: 44px;

    color: #212529;
    
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;

    border: none;
    border-bottom: 1px solid #c3c3c3;
}

.form-singleline:focus
{
    outline-color: #298ce8;
}

.form-singleline-dropdown
{
    margin-top: 15px;
    padding-top: 15px;
    padding-bottom: 5px;

    width: 100%;
    height: 44px;

    color: #212529;
    
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -webkit-appearance: none;

    border: none;
    border-bottom: 1px solid #c3c3c3;
    border-radius: 0;
    
    background-image: url('/public/image/icon_dropdown.svg');
    background-size: 12px 12px;
    background-repeat: no-repeat;
    background-position: right 12px top 50%;
}

.form-singleline-dropdown:focus
{
    outline-color: #298ce8;
}

.form-text
{   
    margin-bottom: 8px;

    color: #212529;

    text-decoration: none;
    font-size: 16px;
    line-height: 19px;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}