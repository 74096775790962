.panel-header
{
    margin-bottom: 20px;

    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    text-align: center;

    color: #a6a6a6;
}

.panel-header-slideshow
{
    padding-bottom: 20px;

    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    text-align: center;

    color: #a6a6a6;
    background-color: #f5f5f5;
}

.panel-info-container-mobile
{
    margin-top: 12px;
    margin-bottom: 0px;
    margin-left: -12px;
    margin-right: -12px;

    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 12px;
    padding-right: 12px;
    
    color: #2a6aa3;
    background-color: #dbeeff;
}

.panel-info-container-desktop
{
    padding: 24px;

    color: #2a6aa3;
    background-color: #dbeeff;
}

.panel-info-icon
{
    display: inline-block;
    
    margin-right: 5px;

    width: 18px;
    height: 18px;

    vertical-align: text-top;
    
    background-image: url('/public/image/icon_info.svg');
    background-size: 18px 18px;
    background-repeat: no-repeat;
    background-position: center;
}

.panel-intro-container-mobile
{
    display: flex;
    flex-flow: column;

    margin-top: 12px;
    margin-bottom: 0px;
    margin-left: -12px;
    margin-right: -12px;

    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 12px;
    padding-right: 12px;
    
    background-color: #ffffff;
}

.panel-intro-container-desktop
{
    display: flex;
    flex-flow: column;

    padding: 24px;

    background-color: #ffffff;
}

.panel-section-info
{
    padding-bottom: 64px;
    
    background-color: #f5f5f5;
}

.panel-section-last
{
    padding-top: 64px;
    padding-bottom: 64px;
    
    background-color: #f5f5f5;
}

.panel-spacing
{
    height: 48px;

    background-color: #f5f5f5;
}

.panel-text
{
    padding-top: 8px;
    padding-bottom: 0px;
    margin-bottom: 0px;

    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    text-align: left;

    color: #000000;
}